// import React from "react";
import "./Banner.scss";
import React, { useState, useEffect } from 'react';
import BannerImg from "../Assets/banner-img.jpg";
import { MdClose } from "react-icons/md";


const Banner = () => {
    const [showPopup, setShowPopup] = useState(false);

    // Show the popup when the component is mounted
    useEffect(() => {
        setShowPopup(true);
    }, []);

    const handleClose = () => {
        setShowPopup(false);
    };


    return (
        <div >
            {showPopup && (
                <div className="banner-main">
                    <div className="banner-content">
                        <img src={BannerImg} alt="POPUP BANNER" />
                        <p>Book your tickets now </p>
                        <a href="tel: 080 22222224" target="_blank" className="banner-cta">
                            Call us 080-22222224
                        </a>
                        <a onClick={handleClose} className="close-popup"><MdClose className="close-icon" /></a>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Banner;
