import "./App.css";
import Routers from "./Routers/Router";
import AOS from "aos";
import "aos/dist/aos.css";
import Banner from './AdBanner/Banner';


function App() {
  AOS.init();

  return (
    <div className="App">
      <Banner />
      
      <Routers />
    </div>
  );
}

export default App;
